import BR from 'primitives/Layout/BR';
import DummyTiles from '../DummyTiles';
import EmptyState from '../EmptyState';
import InfiniteScroll from 'react-infinite-scroller';
import NavLink from 'components/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PodcastTiles from 'components/PodcastTiles/PodcastTiles';
import SectionHeaderText from '../SectionHeaderText';
import ShouldShow from 'components/ShouldShow';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { H4, TilesWrapper } from '../primitives';
import { Mic } from 'styles/icons';
import { Params } from 'state/YourLibrary/getFollowedPodcasts';
import { Podcast } from 'state/Podcast/types';
import { TILE_RES } from 'components/MediaServerImage';
import { TILES_IN_ROW } from '../constants';
import { useCallback } from 'react';

export type Props = {
  followed: Array<Podcast>;
  nextPageKey?: string | null;
  receivedFollowed: boolean;
  requestFollowed: ({ limit, pageKey }: Params) => void;
};

function Podcasts({
  followed: podcasts,
  nextPageKey,
  receivedFollowed,
  requestFollowed,
}: Props) {
  const translate = useTranslate();

  const loadMore = useCallback(() => {
    if (!receivedFollowed || nextPageKey) {
      requestFollowed({ limit: 30, pageKey: nextPageKey });
    }
  }, [receivedFollowed, nextPageKey, requestFollowed]);

  useMount(() => {
    if (!receivedFollowed) {
      loadMore();
    }
  });
  const dummyTiles = [...DummyTiles({ noRoundTiles: true })];

  return (
    <>
      <H4
        data-test="your-library-podcasts-title"
        hideHeader={podcasts.length === 0}
      >
        <SectionHeaderText>{translate('Podcasts')} (A-Z)</SectionHeaderText>
      </H4>

      <ShouldShow shouldShow={podcasts.length > 0 || !receivedFollowed}>
        <BR />
        <TilesWrapper>
          <InfiniteScroll
            hasMore={receivedFollowed && !!nextPageKey}
            loadMore={loadMore}
            pageStart={0}
          >
            {podcasts.length ? (
              <PodcastTiles
                imgWidth={TILE_RES}
                itemSelectedLocation="yourlibrary|podcasts|tile"
                podcasts={podcasts}
                tilesInRow={TILES_IN_ROW}
              />
            ) : (
              dummyTiles
            )}
          </InfiniteScroll>
        </TilesWrapper>
      </ShouldShow>

      <ShouldShow shouldShow={podcasts.length === 0}>
        <EmptyState
          buttons={[
            <NavLink
              dataTest="podcast-empty-link"
              key="podcasts-empty-link"
              to="/podcast/"
            >
              <OutlinedButton>{translate('Browse Podcasts')}</OutlinedButton>
            </NavLink>,
          ]}
          icon={Mic}
          subtitle={translate('Need a great new podcast to start?')}
          title={translate('Any podcasts you follow will show up here')}
        />
      </ShouldShow>
    </>
  );
}

export default Podcasts;
